import "styles/pages/single-contest.scss";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";
import SectionTitle from "components/SectionTitle";
import Tabs from "components/Tabs";
import ProjectBanner from "components/ProjectBanner";
import VotePopup from "components/VotePopup";
import classNames from "classnames";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReactPlayer from "react-player";

const SingleContestPage = ({ pageContext }) => {
   const [modal, setModal] = useState(false);
   const [videoUrl, setVideoUrl] = useState("");
   const [photoIndex, setPhotoIndex] = useState(0);
   const [isLightboxOpen, setIsLightboxOpen] = useState(false);

   // Votes
   const [isPopupVisible, setIsPopupVisible] = useState(false);
   const [votes, setVotes] = useState(Number(pageContext.votes));
   const [label, setLabel] = useState("głosów");

   const closePopup = () => setIsPopupVisible(false);

   const page = pageContext.data;
   const seo = pageContext.seo;
   const category = pageContext.category.nodes[0];
   const gallery = page.galleryImg?.map((item) => item.sourceUrl);
   const tabsContent = page.tabs;

   const date_end_formatted = `${page.endDate} 12:00`;

   const is_between = moment().isBetween(
      moment(page.startDate, "DD/MM/YYYY"),
      moment(date_end_formatted, "DD/MM/YYYY HH:mm")
   );

   useEffect(() => {
      fetch(
         `https://malopolska-lokalnie.zenx.pl/wp-json/adreamvote/v1/get/${pageContext.id}`,
         {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
            },
            method: "GET",
            cache: "no-cache",
            mode: "cors",
         }
      )
         .then((res) => res.json())
         .then((data) => {
            typeof data[0].NumberOfVote !== undefined
               ? setVotes(data[0].NumberOfVote)
               : setVotes("0");
         })
         .catch((res) => {
            console.error(res);
         });
      if (votes === 1) {
         setLabel("głos");
      } else if (votes % 10 === 2 || votes % 10 === 3 || votes % 10 === 4) {
         setLabel("głosy");
      } else {
         setLabel("głosów");
      }
   }, [votes]);

   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
            ogImage={page.mainImage?.sourceUrl}
         />
         <Breadcrumbs
            currentLocationLabel={pageContext.title}
            prevLocationLabel="Głosowanie"
            prevLocationLink="/glosowanie"
         />
         <section className="single-contest">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row">
                  <div className="col-xl-4 col-lg-5">
                     <div className="single-contest__status-text">
                        Status konkursu:{" "}
                        <span
                           className={classNames(`single-contest__status`, {
                              [`single-contest__status--active`]:
                                 page.status === "Aktywny",
                              [`single-contest__status--finished`]:
                                 page.status === "Zakończony",
                           })}
                        >
                           {page.status}
                        </span>
                        <div className="single-contest__date">
                           {page.startDate} - {page.endDate}
                        </div>
                     </div>
                     <div
                        className="single-contest__thumbnail"
                        style={{
                           backgroundImage: `url(${page.mainImage?.sourceUrl})`,
                        }}
                     >
                        <div
                           className="single-contest__category"
                           style={{
                              backgroundColor: category?.votesCategory?.color,
                           }}
                        >
                           {category?.name}
                        </div>
                        <div className="single-contest__votes">
                           {votes} <span>{label}</span>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-7 offset-xl-1">
                     <Tabs items={tabsContent} />
                  </div>
               </div>
               <div className="single-contest__section-title">Załączniki</div>
               <div className="row">
                  {page.galleryImg?.map((item, index) => {
                     return (
                        <div className="col-xl-2 col-lg-3 col-md-4" key={index}>
                           <div
                              className="single-contest__file"
                              style={{
                                 backgroundImage: `url(${item.sourceUrl})`,
                              }}
                              onClick={(e) => {
                                 e.preventDefault();
                                 setIsLightboxOpen(true);
                                 setPhotoIndex(index);
                              }}
                              aria-hidden="true"
                           />
                        </div>
                     );
                  })}
                  {page.galleryVideo?.map((item, index) => {
                     return (
                        <div className="col-xl-2 col-lg-3 col-md-4" key={index}>
                           <div
                              className="single-contest__file single-contest__file--video"
                              style={{
                                 backgroundImage: `url(${item.thumbnail?.sourceUrl})`,
                              }}
                              onClick={(e) => {
                                 e.preventDefault();
                                 setModal(true);
                                 setVideoUrl(item.link);
                              }}
                              aria-hidden="true"
                           >
                              <img
                                 src={
                                    require("assets/icons/play-video.svg")
                                       .default
                                 }
                                 className="img-fluid single-contest__play-arrow"
                                 alt="Strzałka"
                              />
                           </div>
                        </div>
                     );
                  })}
               </div>
               {page.status === "Aktywny" && is_between && (
                  <div className="single-contest__banner-wrapper">
                     <ProjectBanner
                        onClick={() => setIsPopupVisible(true)}
                        btnText="Oddaj swój głos"
                        title="Podoba ci się ten projekt?"
                     />
                  </div>
               )}
            </div>
         </section>
         {page.status === "Aktywny" && is_between && (
            <VotePopup
               visible={isPopupVisible}
               onClose={closePopup}
               pageId={pageContext.id}
               pageName={pageContext.title}
            />
         )}
         {isLightboxOpen && (
            <Lightbox
               mainSrc={gallery[photoIndex]}
               nextSrc={gallery[(photoIndex + 1) % gallery.length]}
               prevSrc={
                  gallery[(photoIndex + gallery.length - 1) % gallery.length]
               }
               onCloseRequest={() => setIsLightboxOpen(false)}
               onMovePrevRequest={() =>
                  setPhotoIndex(
                     (photoIndex + gallery.length - 1) % gallery.length
                  )
               }
               onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % gallery.length)
               }
            />
         )}
         {modal && (
            <>
               <div className="subpage-modal subpage-modal--main-modal">
                  <div className="subpage-modal__wrapper">
                     <button
                        className="subpage-modal__close"
                        onClick={(e) => setModal(false)}
                     >
                        &#10005;
                     </button>
                     <div className="embed-container">
                        <ReactPlayer
                           url={videoUrl}
                           width="100%"
                           heigth="100%"
                           volume={1}
                           playing={modal === true ? true : false}
                           controls={true}
                           muted={modal === true ? false : true}
                           playsinline={true}
                        />
                     </div>
                  </div>
               </div>
               <div
                  className="subpage-modal__overlay"
                  onClick={(e) => setModal(false)}
                  role="presentation"
               ></div>
            </>
         )}
      </Layout>
   );
};

export default SingleContestPage;
